@tailwind base;
@tailwind components;
@tailwind utilities;
/* html,
body {
  padding: 0;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen,
    Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
}

a {
  color: inherit;
  text-decoration: none;
}

* {
  box-sizing: border-box;
}

.data-row {
  @apply px-6 py-4 whitespace-no-wrap text-sm leading-5 text-cool-gray-500;
}

.card {
  @apply p-6 bg-white rounded-md border border-gray-200 mb-2;
}

.h3 {
  @apply text-lg leading-6 font-semibold;
}

.sidebar{
  background: #252f40;
  color: #fff;
  height: 200vh;
  width: 300px;
  transition: all 0.5s;
}
.top_section{
  display: flex;
  align-items:center;
  padding:20px 15px;
}
.logo{
  font-size: 30px;
}
.bars{
  display: flex;
  font-size: 25px;
  margin-left: 115px;
  color: #fff;
}

@layer base {
  body {
    @apply bg-gray-100;
  }
} */