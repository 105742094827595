@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";

.btn {
  @apply w-full justify-center py-3 border border-transparent text-sm leading-5 font-medium rounded-md text-white transition duration-150 ease-in-out;
}

.btn:active {
  @apply bg-blue-700;
}

.btn:focus {
  @apply outline-none;
}

.btn-blue {
  @apply bg-blue-600;
}

.btn-blue:hover {
  @apply bg-blue-500;
}

.btn-blue:active {
  @apply bg-blue-700;
}

.btn-blue:focus {
  @apply border-blue-700 shadow-outline-blue;
}

.btn-gray {
  @apply bg-gray-200 text-red-500;
}

.btn-gray:hover {
  @apply text-red-600;
}

.btn-gray:active {
  @apply text-red-600;
}

.btn-red {
  @apply bg-red-700;
}

.btn-red:hover {
  @apply bg-red-600;
}

.btn-white {
  @apply text-gray-400 border border-gray-200;
}

.btn-white:hover {
  @apply text-gray-500;
}

/* tournament event */

.draggable-table-tournamentevent {
	position: relative;
  border-collapse: collapse;
  /* -webkit-box-shadow: 0px 0px 10px 8px rgba(0, 0, 0, 0.1);
          box-shadow: 0px 0px 10px 8px rgba(0, 0, 0, 0.1);   */
  cursor: move; /* fallback: no `url()` support or images disabled */
  cursor: -webkit-grab; /* Chrome 1-21, Safari 4+ */
  cursor:    -moz-grab; /* Firefox 1.5-26 */
  cursor:         grab; /* W3C standards syntax, should come least */
  
  .draggable-table-tournamentevent__drag {
      font-size: .95em;
      /* font-weight: lighter; */
      text-transform: capitalize;    
      position: absolute;
      /* text-indent: 50px;     */
      border: 1px solid #9d0cb3;
      z-index: 10;
      cursor: grabbing;
      -webkit-box-shadow: 2px 2px 3px 0px rgba(0, 0, 0, 0.05);
              box-shadow: 2px 2px 3px 0px rgba(0, 0, 0, 0.05);
  }

  .is-dragging-tournamentevent {
    background-color: white;
  }
}

.draggable-table-poolprize {
	position: relative;
  border-collapse: collapse;
  /* -webkit-box-shadow: 0px 0px 10px 8px rgba(0, 0, 0, 0.1);
          box-shadow: 0px 0px 10px 8px rgba(0, 0, 0, 0.1);   */
  cursor: move; /* fallback: no `url()` support or images disabled */
  cursor: -webkit-grab; /* Chrome 1-21, Safari 4+ */
  cursor:    -moz-grab; /* Firefox 1.5-26 */
  cursor:         grab; /* W3C standards syntax, should come least */
  
  .draggable-table-poolprize__drag {
      font-size: .95em;
      /* font-weight: lighter; */
      text-transform: capitalize;    
      position: absolute;
      /* text-indent: 50px;     */
      border: 1px solid #9d0cb3;
      z-index: 10;
      cursor: grabbing;
      -webkit-box-shadow: 2px 2px 3px 0px rgba(0, 0, 0, 0.05);
              box-shadow: 2px 2px 3px 0px rgba(0, 0, 0, 0.05);
  }

  .is-dragging-poolprize {
    background-color: white;
  }
}
/* tournament event */

 
/* torunament room */
 .app-header {
   display: grid;
   grid-template-columns: 1fr 1fr 1fr;
   align-items: center;
   padding: 1.5rem 1rem;
 }
 .app-header .left,
 .app-header .right {
   display: grid;
   grid-gap: 7px;
 }
 .app-header .left {
   grid-template-columns: repeat(3, max-content);
 }
 .app-header .center {
   text-align: center;
 }
 .app-header .right {
   justify-content: end;
   grid-template-columns: repeat(5, max-content);
 }
 .app-header .logo {
   font-size: 1.25em;
   color: #000;
   font-weight: bold;
 }
 .app-header .logo i {
   font-style: normal;
 }
 .app-header .search {
   display: flex;
   justify-content: space-between;
   min-width: 200px;
   background-color: #ebecf0;
   color: #9d9ea3;
   border-radius: 8px;
   padding: 0.8rem;
 }
 .app-board {
   display: grid;
   grid-template-rows: max-content auto;
 }
 .app-board .board-header {
   display: grid;
   grid-template-columns: repeat(2, auto);
   padding: 0.5rem;
 }
 .app-board .board-header .left,
 .app-board .board-header .right {
   display: grid;
   grid-gap: 7px;
   align-items: center;
 }
 .app-board .board-header .left .btn,
 .app-board .board-header .right .btn {
   color: var(--app-col);
 }
 .app-board .board-header .left {
   grid-template-columns: repeat(4, max-content);
 }
 .app-board .board-header .right {
   justify-content: end;
   grid-template-columns: repeat(2, max-content);
 }
 .app-board .board-header .title {
   font-weight: 700;
   font-size: 1.25em;
 }
 .app-board .board-body {
   padding: 0 0.5rem;
   /* overflow-y: hidden; */
   height: 100vh;
 }
 .app-board .board-body .wrap-lists {
   display: grid;
   grid-auto-columns: 450px;
   grid-auto-flow: column;
   grid-gap: 7px;
   height: calc(100% - 16px);
   padding-bottom: 0.5rem;
   /* overflow-y: auto; */
 }
 .app-board .board-body .list {
   display: grid;
   grid-auto-rows: max-content;
   grid-gap: 10px;
   padding: 0.5rem;
   font-size: 0.875em;
   /* background: rgba(255, 255, 255, 0.5); */
   border-radius: 8px;
 }
 .app-board .board-body .list .list-title {
   margin: 0;
 }
 .app-board .board-body .list .boardContentArea {
   min-height: 15px;
 }
 .app-board .board-body .list .boardContentArea.hovered {
   padding-bottom: 15px;
 }
 .app-board .board-body .list .card {
   padding: 1rem 1.2rem;
   background: #fff;
   border-radius: 8px;
   box-shadow: 0px 10px 17px -10px rgba(0, 0, 0, 0.59);
   margin-bottom: 10px;
   position: relative;
 }
 .app-board .board-body .list .card.hidden {
   display: none;
 }
 .app-board .board-body .list .card.ohhold {
   border: 2px solid #fff;
   transform: rotateZ(-2deg);
 }
 .app-board .board-body .list .card.anotherCardOnTop {
   margin-top: 5px;
 }
 .app-board .board-body .list .card .cardTitle {
   font-size: 0.8rem;
   font-weight: bold;
   margin-bottom: 8px;
 }
 .app-board .board-body .list .card .cardFooter {
   display: flex;
   justify-content: space-between;
   align-items: center;
 }
 .app-board .board-body .list .card .cardFooter .label {
   font-size: 0.7rem;
   padding: 0.2rem 0.5rem;
   background-color: rgba(0, 0, 0, 0.1);
   color: rgba(0, 0, 0, 1);
   border-radius: 0.8rem;
 }
 .app-board .board-body .list .card .cardFooter .label.color1 {
   background-color: #ff00001a;
   color: #ff0000;
 }
 .app-board .board-body .list .card .cardFooter .label.color2 {
   background-color: #0020711a;
   color: #002071;
 }
 .app-board .board-body .list .card .cardFooter .label.color3 {
   background-color: #c560001a;
   color: #c56000;
 }
 .app-board .board-body .list .card .cardFooter .collab {
   display: flex;
 }
 .app-board .board-body .list .card .cardFooter .collab .collabPerson {
   display: block;
   width: 30px;
   height: 30px;
   margin-left: -5px;
   border: 2px solid #fff;
   border-radius: 50%;
   background-color: #ccc;
   display: flex;
   justify-content: center;
   align-items: center;
 }
 
 .app-board .board-body .list .btn-list {
   text-decoration: none;
   color: rgba(0, 0, 0, 0.5);
 }
 .app-tint {
   position: fixed;
   bottom: 1rem;
   right: 1rem;
 }
 .app-tint input[type="color"] {
   position: absolute;
   top: 0;
   left: 0;
   z-index: -1;
   opacity: 0;
 }
 .app-tint label {
   font-size: 1.25em;
   color: var(--app-col);
   cursor: pointer;
 }

.input-container {
  position: relative;
}

.input-container input {
  padding-right: 100px;
}

.static-text {
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
}

.grabbable-tournamentroom {
  cursor: move; /* fallback if grab cursor is unsupported */
  cursor: grab;
  cursor: -moz-grab;
  cursor: -webkit-grab;
}

/* (Optional) Apply a "closed-hand" cursor during drag operation. */
.grabbable-tournamentroom:active {
  cursor: grabbing;
  cursor: -moz-grabbing;
  cursor: -webkit-grabbing;
}

/* tournament room */